:root {
	color-scheme: dark;
	--neutral-11: #15171e;
	--neutral-10: #0c0e13;
	--neutral-9: #111219;
	--neutral-8: #1c1e22;
	--neutral-7: #1e1f25;
	--neutral-6: #23252b;
	--neutral-5: #2c2e34;
	--neutral-4: #737478;
	--neutral-3: #7e7f83;
	--neutral-2: #8a8b8f;
	--neutral-1: #ffffff;

	--neutral-6-transparent: rgba(35, 37, 43, 0.9);
	--neutral-6-transparent-8: rgba(35, 37, 43, 0.8);
	--neutral-6-transparent-7: rgba(35, 37, 43, 0.7);

	--neutral-8-transparent: rgba(28, 30, 34, 0.9);
	--neutral-8-transparent-8: rgba(28, 30, 34, 0.8);

	--black: #000;
	--white: #fff;

	--accent: #d74691;
	--accent-light: #d65a9a;

	--green: #83b547;
	--green-hover: #9ddb51;
	--blue: #2c97de;
	--blue-hover: #36a9f5;
	--facebook-blue: #3c5a99;
	--google-blue: #4285f4;
	--red: #cc3e4a;
	--red-hover: #e8414f;
	--patreon-red: #e85b46;
	--discord-purple: #5865f2;
	--orange: #e76d3b;
	--yellow: #c1b947;
	--purple: #772caa;
	--blue-light: #88b3ce;
	--green-light: #9ac16a;
	--orange-light: #db815e;
	--red-light: #c45e66;
	--yellow-light: #e2da5f;
	--yellow-dark: #87801b;

	--copper: #e76d3b;
	--silver: #dbdbdb;
	--electrum: #a2bade;
	--gold: #e2da5f;
	--platinum: #fff;

	//damage type colors
	--acid: #83b547;
	--bludgeoning: #a5aab4;
	--cold: #3b93ce;
	--fire: #e63f21;
	--force: #d74691;
	--lightning: #88b3ce;
	--necrotic: #772caa;
	--piercing: #a5aab4;
	--poison: #83b547;
	--psychic: #e63f21;
	--radiant: #e2da5f;
	--slashing: #a5aab4;
	--thunder: #e63f21;

	// Styling
	--font-size: 15px;
	--header-height: 50px;
	--border-radius: 4px;
	--border-radius-small: 3px;
}

// Possible light theme
[data-theme="light"] {
	color-scheme: light;
	--neutral-11: #ffffff;
	--neutral-10: #eaf1fb;
	--neutral-9: #f2f6fc;
	--neutral-8: #f6f8fc;
	--neutral-7: #eaf1fb;
	--neutral-6: #ffffff;
	--neutral-5: #c2e7ff;
	--neutral-4: #8cb7d4;
	--neutral-3: #638ba6;
	--neutral-2: #0d2d43;
	--neutral-1: #060607;

	--neutral-6-transparent: rgba(255, 255, 255, 0.9);
	--neutral-6-transparent-8: rgba(255, 255, 255, 0.8);
	--neutral-6-transparent-7: rgba(255, 255, 255, 0.7);

	--neutral-8-transparent: rgba(246, 248, 252, 0.9);
	--neutral-8-transparent-8: rgba(246, 248, 252, 0.8);

	--green: #0c940c;
	--green-hover: #008a00;
	--blue: #0078d3;
	--blue-hover: #0263ad;
	--red: #d30100;
	--red-hover: #b30202;
	--orange: #ffaa44;

	--radiant: #e2da5f;
}

/* 1. Variables */
/*********************************************/
$neutral-11: var(--neutral-11);
$neutral-10: var(--neutral-10);
$neutral-9: var(--neutral-9);
$neutral-8: var(--neutral-8);
$neutral-7: var(--neutral-7);
$neutral-6: var(--neutral-6);
$neutral-5: var(--neutral-5);
$neutral-4: var(--neutral-4);
$neutral-3: var(--neutral-3);
$neutral-2: var(--neutral-2);
$neutral-1: var(--neutral-1);

$neutral-6-transparent: var(--neutral-6-transparent);
$neutral-6-transparent-8: var(--neutral-6-transparent-8);
$neutral-6-transparent-7: var(--neutral-6-transparent-7);

$neutral-8-transparent: var(--neutral-8-transparent);
$neutral-8-transparent-8: var(--neutral-8-transparent-8);

$black: var(--black); // Keep
$white: var(--white); // Keep

$accent: var(--accent);
$accent-light: var(--accent-light);

$green: var(--green);
$green-hover: var(--green-hover);
$blue: var(--blue);
$blue-hover: var(--blue-hover);
$facebook-blue: var(--facebook-blue);
$google-blue: var(--google-blue);
$red: var(--red);
$red-hover: var(--red-hover);
$patreon-red: var(--patreon-red);
$discord-purple: var(--discord-purple);
$orange: var(--orange);
$yellow: var(--yellow);
$purple: var(--purple);
$blue-light: var(--blue-light);
$green-light: var(--green-light);
$orange-light: var(--orange-light);
$red-light: var(--red-light);
$yellow-light: var(--yellow-light);
$yellow-dark: var(--yellow-dark);

$copper: var(--copper);
$silver: var(--silver);
$electrum: var(--electrum);
$gold: var(--gold);
$platinum: var(--platinum);

//damage type colors
$acid: var(--acid);
$bludgeoning: var(--bludgeoning);
$cold: var(--cold);
$fire: var(--fire);
$force: var(--force);
$lightning: var(--lightning);
$necrotic: var(--necrotic);
$piercing: var(--piercing);
$poison: var(--poison);
$psychic: var(--psychic);
$radiant: var(--radiant);
$slashing: var(--slashing);
$thunder: var(--thunder);

// Styling
$font-size: var(--font-size);
$header-height: var(--header-height);
$border-radius: var(--border-radius);
$border-radius-small: var(--border-radius-small);
$text-written: "Fredericka the Great", cursive;
$outline: var(--neutral-2) solid 3px;

// Breakpoints
$sm-breakpoint: 576px;
$md-breakpoint: 768px;
$lg-breakpoint: 992px;
$xl-breakpoint: 1200px;

/**
* Quasar
* @see https://v1.quasar.dev/style/sass-scss-variables#variables-list
*/
$primary: var(--blue);
$secondary: #26a69a;
$accent: var(--accent);

$dark: #15171e;

$positive: var(--green);
$negative: var(--red);
$info: var(--yellow);
$warning: var(--orange);
